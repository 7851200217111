import * as React from 'react'

import { Image } from '@adverity/design-system'

import type { Assets } from './generated/assets'
import { assetsMap } from './generated/assets'

type Props = {
    src: Assets
    maxWidth?: number
} & Omit<React.ComponentProps<typeof Image>, 'src'>

export const StaticImage = ({ alt, src, ...props }: Props) => {
    const imageUrl = assetsMap[src]

    return <Image {...props} alt={alt} src={imageUrl} />
}

import * as React from 'react'

import ResizeObserver from 'resize-observer-polyfill'

type Rect = Omit<DOMRectReadOnly, 'toJSON'>

export const useMeasure = <T extends Element = HTMLDivElement>(): [React.RefObject<T>, Rect] => {
    const [rect, setRect] = React.useState<Rect>({
        width: 0,
        height: 0,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        x: 0,
        y: 0,
    })

    const resizeObserver = React.useMemo(
        () =>
            new ResizeObserver(([entry]) => {
                if (entry) {
                    setRect(entry.contentRect)
                }
            }),
        [],
    )

    const ref = React.useRef<T>(null)

    React.useEffect(() => {
        resizeObserver.disconnect()
        const target = ref.current

        if (target) {
            resizeObserver.observe(target)
        }

        return () => {
            if (target) {
                resizeObserver.unobserve(target)
            }
        }
    }, [resizeObserver])

    return [ref, rect]
}

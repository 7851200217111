import * as React from 'react'

type PageConfigurationContextConfig = {
    user?: {
        email: string
        id: number
    }
    successUrl?: string
    backUrl?: string
    stackId?: number
    stackUuid?: string
    stackSlug?: string
    isPortal?: boolean
}

const PageConfigurationContext = React.createContext<PageConfigurationContextConfig>({})

export const PageConfigurationContextProvider = ({
    children,
    ...value
}: PageConfigurationContextConfig & { children: JSX.Element }) => (
    <PageConfigurationContext.Provider value={value}>{children}</PageConfigurationContext.Provider>
)

const usePageConfiguration = () => React.useContext(PageConfigurationContext)

export const useCurrentStackId = () => usePageConfiguration().stackId ?? 0

export const useCurrentStackUuid = () => usePageConfiguration().stackUuid ?? ''

export const useCurrentStackSlug = () => usePageConfiguration().stackSlug ?? ''

export const useUser = () => usePageConfiguration().user

export const useSuccessUrl = () => usePageConfiguration().successUrl

export const useBackUrl = () => usePageConfiguration().backUrl
